<template>
<div class="dashboard-container">
    <div class="header-container">
        <h5><font-awesome-icon icon="flag" class="mr-10"/> Lapor Keluhan Sistem</h5>
	</div>
	<hr class="bottom-devider"/>
	<div class="body-container">
        <CJumbotron>
            <p class="lead">Mohon maaf atas ketidaknyamanan yang dialami</p>
            <p>
                Kami berterima kasih atas saran yang Anda berikan.
                Saran Anda sangatlah berarti bagi kami untuk meningkatkan pengalaman pengguna.
                Silahkan untuk memasukkan saran atau keluhan yang Anda alami untuk segera kami tangani.
            </p>
            <CTextarea
                v-model="problem"
                :is-valid="validate.problem"
                label="Saran/Keluhan"
                placeholder="Saran/Keluhan"
                rows="5"
                @input="validateForm()" />
            <CButton @click="postReport()" color="success" class="btn-sm text-white" :disabled="submitBtn.disabled">
                <font-awesome-icon v-if="submitBtn.loader" icon="spinner" spin/> Kirim
            </CButton>
        </CJumbotron>
    </div>
</div>
</template>

<script>
export default {
	names: 'Report',
	data () {
		return {
            problem: null,
            validate: {
                problem: false
            },
            submitBtn: {
                disabled: true,
                loader: false
            }
		}
	},
	methods: {
		postReport () {
            const problem = this.problem
            if (problem === null || problem === '')
                return false

            this.submitBtn.loading = true
            try {
                this.$store.dispatch({
                    type: 'faq/postReport',
                    formInput: {
                        problem: this.problem
                    }
                }).then( res => {
                    this.problem = null
                    this.submitBtn.disabled = true
                    this.$toasted.success('Terima kasih, kami akan segera memproses saran atau keluhan anda').goAway(4000)
                }).catch( err => {
                    console.log('Failed store problem report')
                    console.log(err)
                    this.$toasted.error('Gagal mengirimkan laporan keluhan, mohon untuk menghubungi administrator').goAway(3000)
                }).finally(() => {
                    this.submitBtn.loading = false
                })
            } catch (err) {
                console.log('Failed store problem report')
                console.log(err)
                this.$toasted.error('Gagal mengirimkan laporan keluhan, mohon untuk menghubungi administrator').goAway(3000)
                this.submitBtn.loading = false
            }
		},
        validateForm () {
            if (this.problem != null && this.problem != '') {
                this.validate.problem = true
            } else {
                this.validate.problem = false
            }

            if (this.validate.problem) {
                this.submitBtn.disabled = false
            } else {
                this.submitBtn.disabled = true
            }
        }
	}
}
</script>

<style scoped lang="scss">
.mt-10 {
	margin-top: 10px !important;
}
.mr-10 {
	margin-right: 10px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
</style>